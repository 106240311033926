import { lazy } from "react";
import { Navigate } from "react-router";
import Loadable from "src/routes/Loadable";

const Ewallet = Loadable(
  lazy(() => import("src/pages/admin/financial/ewallet"))
);

const DepositWallet = Loadable(
  lazy(() => import("src/pages/admin/financial/deposit"))
);

const FundCredits = Loadable(
  lazy(() => import("src/pages/admin/financial/fundCredit/index"))
);

const Payout = Loadable(
  lazy(() => import("src/pages/admin/financial/payout/index"))
);

const PayoutRequest = Loadable(
  lazy(() =>
    import("src/pages/admin/financial/payout/components/request/index")
  )
);

const PayoutHistory = Loadable(
  lazy(() =>
    import("src/pages/admin/financial/payout/components/history/index")
  )
);

const FinancialReport = Loadable(
  lazy(() =>
    import("src/pages/admin/financial/financialReport/financialReport")
  )
);

const LatestSales = Loadable(
  lazy(() => import("src/pages/admin/financial/sales/index"))
);

const Voucher = Loadable(
  lazy(() => import("src/pages/admin/financial/voucher/index"))
);

const VoucherRequest = Loadable(
  lazy(() =>
    import("src/pages/admin/financial/voucher/components/request/index")
  )
);

const VoucherHistory = Loadable(
  lazy(() =>
    import("src/pages/admin/financial/voucher/components/history/index")
  )
);

const OrderApproval = Loadable(
  lazy(() => import("src/pages/admin/financial/orderApproval/index"))
);

const AdminWallet = Loadable(
  lazy(() => import("src/pages/admin/financial/admin-wallet/index"))
);

// const Bank = Loadable(
//   lazy(() =>
//     import("src/pages/admin/financial/orderApproval/components/orders")
//   )
// );

// const Stripe = Loadable(
//   lazy(() =>
//     import("src/pages/admin/financial/orderApproval/components/orders")
//   )
// );

const BinaryCommission = Loadable(
  lazy(() => import("src/pages/admin/financial/binary-commission/index"))
);

const LoadWallet = Loadable(
  lazy(() => import("src/pages/admin/financial/load-wallet/index"))
);

const MembersWallet = Loadable(
  lazy(() => import("src/pages/admin/financial/membersWallet/index"))
);

const financial = [
  {
    path: "financial",
    children: [
      { element: <Navigate to="e-wallet" />, index: true },
      { path: "e-wallet", element: <Ewallet /> },
      { path: "deposit-wallet", element: <DepositWallet /> },
      { path: "fund-credits", element: <FundCredits /> },
      { path: "admin-wallet", element: <AdminWallet /> },
      { path: "load-wallet", element: <LoadWallet /> },
      { path: "members-wallet", element: <MembersWallet /> },
      {
        path: "payout",
        element: <Payout />,
        children: [
          { index: true, element: <Navigate to="request" replace /> },
          { path: "request", element: <PayoutRequest /> },
          { path: "history", element: <PayoutHistory /> },
        ],
      },
      { path: "report", element: <FinancialReport /> },
      { path: "sales", element: <LatestSales /> },
      {
        path: "voucher",
        element: <Voucher />,
        children: [
          { index: true, element: <Navigate to="request" replace /> },
          { path: "request", element: <VoucherRequest /> },
          { path: "history", element: <VoucherHistory /> },
        ],
      },
      {
        path: "order-approval",
        element: <OrderApproval />,
      },
      { path: "order-approval", element: <OrderApproval /> },
      { path: "binary-commission", element: <BinaryCommission /> },
    ],
  },
];

export default financial;

import { lazy } from "react";
import { Navigate } from "react-router";
import AuthGuard, { UserGuard } from "src/guards/AuthGuard";
import Layout from "src/layouts/layout";
import Loadable from "../Loadable";
import businessBuilder from "./businessBuilder";
import financial from "./financial";
import GenealogyRoutes from "./genealogy";
import helpCenter from "./helpCenter";
import profile from "./profile";
import subscriptions from "./subscriptions";
import store from "./store";

const ProductList = Loadable(
  lazy(() =>
    import("src/pages/user/onlineStore/productSubscription/productList/index")
  )
);

const ProductDetails = Loadable(
  lazy(() =>
    import("src/pages/user/onlineStore/productSubscription/details/index")
  )
);

const MyOrders = Loadable(
  lazy(() => import("src/pages/user/onlineStore/myOrders/index"))
);

const ViewOrderById = Loadable(
  lazy(() => import("src/pages/invoice/myOrders/index"))
);

const BlogPosts = Loadable(
  lazy(() => import("src/pages/user/blogs/BlogPosts"))
);

const BlogPost = Loadable(lazy(() => import("src/pages/user/blogs/BlogPost")));

const Dashboard = Loadable(
  lazy(() => import(`src/pages/user/new-dashboard/index`))
);

const IncomeReport = Loadable(
  lazy(() => import("src/pages/user/incomeReport"))
);

const MissedPoints = Loadable(
  lazy(() => import("src/pages/user/missedPoints"))
);

const Events = Loadable(lazy(() => import("src/pages/user/events/index")));

const Checkout = Loadable(
  lazy(() => import("src/pages/user/onlineStore/checkout/index"))
);

const Cart = Loadable(
  lazy(() =>
    import("src/pages/user/onlineStore/checkout/components/cart/index")
  )
);

const Payment = Loadable(
  lazy(() =>
    import("src/pages/user/onlineStore/checkout/components/payment/index")
  )
);

const Leads = Loadable(lazy(() => import("src/pages/lead/user/list")));

const PendingPayments = Loadable(
  lazy(() => import("src/pages/user/onlineStore/pending-payments/index"))
);

const Documents = Loadable(
  lazy(() => import("src/pages/user/helpCenter/DowloadDoc/Index.js"))
);

const DocumentCat = Loadable(
  lazy(() => import("src/pages/user/helpCenter/DowloadDoc/DocCard.js"))
);

const Videos = Loadable(
  lazy(() => import("src/pages/user/helpCenter/video/index.js"))
);
const Coupon = Loadable(
  lazy(() => import("src/pages/user/onlineStore/coupon/index"))
);

const genUser = (userData) => [
  {
    path: "user",
    element: (
      <AuthGuard>
        <UserGuard>
          <Layout />
        </UserGuard>
      </AuthGuard>
    ),
    children: [
      { element: <Navigate to="dashboard" />, index: true },
      {
        path: "dashboard",
        element: <Dashboard />,
      },
      {
        path: "events",
        element: <Events />,
      },

      {
        path: "checkout",
        element: <Checkout />,
        children: [
          { index: true, element: <Cart /> },
          { path: "payment", element: <Payment /> },
        ],
      },
      {
        path: "online-store",
        children: [
          {
            path: ":product_type",
            children: [
              { index: true, element: <ProductList /> },
              { path: ":name", element: <ProductDetails /> },
            ],
          },
          {
            path: "my-orders",
            children: [
              { index: true, element: <MyOrders /> },
              {
                path: ":id",
                element: <ViewOrderById />,
              },
            ],
          },
          {
            path: "pending-payments",
            element: <PendingPayments />,
          },
          {
            path: "coupons",
            element: <Coupon />,
          },
        ],
      },
      {
        path: "blogs",
        children: [
          { index: true, element: <BlogPosts /> },
          { path: ":id", element: <BlogPost /> },
        ],
      },
      {
        path: "marketing-tools",
        children: [
          {
            path: "documents",
            element: <Documents />,
            children: [
              { index: true, element: <Navigate to="presentation" /> },
              { path: ":slug", element: <DocumentCat /> },
            ],
          },

          { path: "videos", element: <Videos /> },
        ],
      },
      { path: "income-report", element: <IncomeReport /> },
      { path: "missed-points", element: <MissedPoints /> },
      { path: "leads", element: <Leads /> },
      { ...businessBuilder },
      ...GenealogyRoutes(userData),
      { ...subscriptions },
      { ...financial },
      { ...helpCenter },
      { ...profile },
      { ...store },
    ],
  },
];

export default genUser;

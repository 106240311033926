import React from "react";
import { ADD, CHANGE_TAB, PRODUCT_RESET, REMOVE, SET_PRODUCT } from "./type";

const productReducer = (state, { type, payload }) => {
  switch (type) {
    case ADD:
      return {
        ...state,
        product: [...state.product, payload.id],
      };
    case REMOVE:
      const prev = [...state.product];
      prev?.splice(payload?.index, 1);
      return {
        ...state,
        product: prev,
      };
    case PRODUCT_RESET:
      return { ...state, product: [] };
    case SET_PRODUCT:
      return { ...state, product: payload };
    case CHANGE_TAB:
      return { ...state, product_tab: payload };
    default:
      return state;
  }
};

export default productReducer;

import { lazy } from "react";
import { Navigate } from "react-router";
import { PLANS } from "src/CONSTANTS";
import PlanConfirm from "src/components/plan-confirm";
import Loadable from "../Loadable";
import PurchaseConfirm from "./PurchaseConfirm";
import useAuth from "src/hooks/useAuth";

const BinaryTree = Loadable(
  lazy(() => import("src/pages/user/genealogy/binary/index"))
);

const BinaryLeg = Loadable(
  lazy(() => import("src/pages/user/genealogy/binaryLeg/index"))
);

const Sponsor = Loadable(
  lazy(() => import("src/pages/user/genealogy/sponsor/index"))
);

const Affiliate = Loadable(
  lazy(() => import("src/pages/user/genealogy/affiliate/index"))
);

const Matrix = Loadable(
  lazy(() => import("src/pages/user/genealogy/matrix/index"))
);

const List = Loadable(
  lazy(() => import("src/pages/user/genealogy/list/index"))
);

const Tree = Loadable(
  lazy(() => import("src/pages/user/genealogy/tree/index"))
);

const MonoLine = Loadable(
  lazy(() => import("src/pages/user/genealogy/mono-line/index"))
);

const HoldingTank = Loadable(
  lazy(() => import("src/pages/user/genealogy/holdingTank/index"))
);

const { binary, matrix, roi, uniLevel, monoLine } = PLANS;

const getPath = () => {
  const plan = localStorage.getItem("plan");
  switch (plan) {
    case binary: {
      return "binary";
    }

    case uniLevel: {
      return "sponsor";
    }

    case matrix: {
      return "matrix";
    }

    default: {
      return "binary";
    }
  }
};

const GenealogyRoutes = (userData) => {
  const isBundle = Boolean(userData?.packageStatus);
  const isMarketer = Boolean(userData?.marketerStatus);

  const routes = [
    {
      path: "genealogy",
      children: [
        { index: true, element: <Navigate to={getPath()} /> },
        {
          path: "binaryLeg",
          element: (
            <PlanConfirm types={[binary, roi]}>
              <BinaryLeg />
            </PlanConfirm>
          ),
        },
        {
          path: "mono-line",
          element: (
            <PlanConfirm types={[monoLine]}>
              <MonoLine />
            </PlanConfirm>
          ),
        },
        {
          path: "list",
          element: <List />,
        },
      ],
    },
  ];

  if (!isBundle && isMarketer) {
    routes[0].children.push(
      {
        path: "tree",
        element: <Tree />,
      },
      {
        path: "sponsor",
        element: <Sponsor />,
      }
    );
  } else if (isBundle && !isMarketer) {
    routes[0].children.push({
      path: "binary",
      element: (
        <PlanConfirm types={[binary, roi]}>
          <PurchaseConfirm>
            <BinaryTree />
          </PurchaseConfirm>
        </PlanConfirm>
      ),
    });
  } else if (isBundle && isMarketer) {
    routes[0].children.push(
      {
        path: "tree",
        element: <Tree />,
      },
      {
        path: "holding-tank",
        element: (
          <PlanConfirm types={[binary, roi]}>
            <HoldingTank />
          </PlanConfirm>
        ),
      },
      {
        path: "binary",
        element: (
          <PlanConfirm types={[binary, roi]}>
            <PurchaseConfirm>
              <BinaryTree />
            </PurchaseConfirm>
          </PlanConfirm>
        ),
      },
      {
        path: "tree",
        element: <Tree />,
      },
      {
        path: "sponsor",
        element: <Sponsor />,
      }
    );
  }
  return routes;
};

export default GenealogyRoutes;

import React from "react";
import { Navigate } from "react-router";
import Ternary from "src/components/ternary";
import useAuth from "src/hooks/useAuth";

const PurchaseConfirm = ({ children }) => {
  const { user } = useAuth();
  const isBundle = Boolean(user.packageStatus);
  const isMarketer = Boolean(user.marketerStatus);
  return (
    <Ternary
      when={user.packageStatus === 0}
      then={<Navigate to="404" replace />}
      otherwise={children}
    />
  );
};

export default PurchaseConfirm;

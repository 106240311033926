import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
// @mui
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
//
import BlockContent from "./BlockContent";
import MultiFilePreview from "./MultiFilePreview";
import RejectionFiles from "./RejectionFiles";

// ----------------------------------------------------------------------

const DropZoneStyle = styled("div")(({ theme }) => ({
  outline: "none",
  padding: theme.spacing(5, 1),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.neutral,
  border: `1px dashed ${theme.palette.grey[500_32]}`,
  "&:hover": { opacity: 0.72, cursor: "pointer" },
}));

// ----------------------------------------------------------------------

UploadMultiFile.propTypes = {
  error: PropTypes.bool,
  showPreview: PropTypes.bool,
  files: PropTypes.array,
  onRemove: PropTypes.func,
  onRemoveAll: PropTypes.func,
  helperText: PropTypes.node,
  sx: PropTypes.object,
};

export default function UploadMultiFile({
  error,
  showPreview = false,
  files,
  onRemove,
  onRemoveAll,
  helperText,
  name,
  sx,
  ...other
}) {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    fileRejections,
  } = useDropzone({
    ...other,
  });
  return (
    <Box sx={{ width: "100%", ...sx }}>
      <DropZoneStyle
        name="upload-image"
        {...getRootProps()}
        sx={{
          ...(isDragActive && { opacity: 0.72 }),
          ...((isDragReject || error) && {
            color: "error.main",
            borderColor: "error.light",
            bgcolor: "error.lighter",
          }),
        }}
      >
        <input {...getInputProps()} />

        <BlockContent maxSize={other.maxSize} />
      </DropZoneStyle>

      {fileRejections.length > 0 && (
        <Typography
          sx={{ px: 2, mt: 1 }}
          variant="caption"
          color="error"
          component="p"
        >
          This is larger file
        </Typography>
        // <RejectionFiles fileRejections={fileRejections} />
      )}

      <MultiFilePreview
        files={files}
        showPreview={showPreview}
        onRemove={onRemove}
        onRemoveAll={onRemoveAll}
      />

      {helperText && helperText}
    </Box>
  );
}

import React, { createContext, useContext } from "react";
import { useReducer } from "react";
import productReducer from "./reducer";

const initialState = {
  product: [],
  product_tab: "approved",
};
export const ProductDataContext = createContext(null);
export const ProductDispatchContext = createContext(null);

const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(productReducer, initialState);
  return (
    <ProductDataContext.Provider value={state}>
      <ProductDispatchContext.Provider value={dispatch}>
        {children}
      </ProductDispatchContext.Provider>
    </ProductDataContext.Provider>
  );
};
export default Provider;

export const useProductDataContext = () => useContext(ProductDataContext);

export const useProductDispatchContext = () =>
  useContext(ProductDispatchContext);

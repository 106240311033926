import { lazy } from "react";
import { Navigate } from "react-router";
import Loadable from "src/routes/Loadable";

const Products = Loadable(
  lazy(() => import("src/pages/user/store/product/index"))
);

const ProductCategories = Loadable(
  lazy(() => import("src/pages/user/store/productCategories/index"))
);

const ProductEdit = Loadable(
  lazy(() =>
    import("src/pages/user/store/product/sub-pages/product-edit/index")
  )
);

const ProductAdd = Loadable(
  lazy(() => import("src/pages/user/store/product/sub-pages/product-add"))
);

const ProductVideo = Loadable(
  lazy(() => import("src/pages/user/store/product/Video"))
);

const DocumentViewEdit = Loadable(
  lazy(() => import("src/pages/user/store/product/document/index"))
);

// const ProductAccess = Loadable(
//   lazy(() => import("src/pages/user/product/productAccess"))
// );
// const ProductQuestions = Loadable(
//   lazy(() => import("src/pages/user/store/product/Questions/Main/index"))
// );

const SampleDocument = Loadable(
  lazy(() => import("src/pages/user/store/product/sampleDocument/index"))
);

const store = {
  path: "store",
  children: [
    { index: true, element: <Navigate to="products" replace /> },
    {
      path: ":product_type",
      children: [
        { element: <Products />, index: true },
        { path: "categories", element: <ProductCategories /> },
        { path: "add", element: <ProductAdd /> },
        {
          path: ":pid",
          children: [
            { element: <ProductEdit />, index: true },

            { path: "video", element: <ProductVideo /> },
            {
              path: "document",
              element: <DocumentViewEdit />,
            },
            //   { path: "access", element: <ProductAccess /> },
            // {
            //   path: "questions",
            //   element: <ProductQuestions />,
            // },
            { path: "sample", element: <SampleDocument /> },
          ],
        },
      ],
    },
  ],
};

export default store;

import isString from "lodash/isString";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
// @mui
import { Box, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
//
import Image from "../Image";
import BlockContent from "./BlockContent";
import RejectionFiles from "./RejectionFiles";
import { useFormContext } from "react-hook-form";

// ----------------------------------------------------------------------
const RootStyle = styled("div")(({ theme }) => ({
  width: 144,
  height: 144,
  margin: "auto",
  borderRadius: "50%",
  padding: theme.spacing(1),
  // border: `1px dashed ${theme.palette.grey[500_32]}`,
}));
const DropZoneStyle = styled("div")(({ theme }) => ({
  outline: "none",
  overflow: "hidden",
  position: "relative",
  padding: theme.spacing(5, 1),
  borderRadius: theme.shape.borderRadius,
  transition: theme.transitions.create("padding"),
  backgroundColor: theme.palette.background.neutral,
  // border: `1px dashed ${theme.palette.grey[500_32]}`,
  "&:hover": { opacity: 0.72, cursor: "pointer" },
  borderRadius: "50%",
  width: 144,
  height: 144,
}));

// ----------------------------------------------------------------------

UploadSingleFileNew.propTypes = {
  error: PropTypes.bool,
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  helperText: PropTypes.node,
  sx: PropTypes.object,
};

export default function UploadSingleFileNew({
  error = false,
  file,
  helperText,
  name,
  sx,
  maxSize,
  ...other
}) {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    fileRejections,
  } = useDropzone({
    multiple: false,
    ...other,
    maxSize,
  });
  const { watch } = useFormContext();
  const imageValue = watch(name);
 
  const isValue = Boolean(imageValue.length) 
  return (
    <Box sx={{ width: "100%", ...sx }}>
      <RootStyle
        sx={{
          ...((isDragReject || error) && {
            borderColor: "error.light",
          }),
          ...sx,
        }}
      >
        <DropZoneStyle
          {...getRootProps()}
          sx={{
            ...(isDragActive && { opacity: 0.72 }),
            ...((isDragReject || error) && {
              color: "error.main",
              borderColor: "error.light",
              bgcolor: "error.lighter",
            }),
            ...(file && {
              padding: "12% 0",
            }),
          }}
          name="image"
        >
          <input {...getInputProps()} />

          <Stack sx={{ textAlign: "center", mt: 3 }}>
            {!isValue? (
              <Typography variant="subtitle2">Upload Logo</Typography>
            ) : null}
          </Stack>

          {file && (
            <Image
              alt="file preview"
              src={isString(file) ? file : file.preview}
              name={name}
              sx={{
                top: 8,
                left: 8,
                borderRadius: "50%",
                position: "absolute",
                width: "calc(100% - 16px)",
                height: "calc(100% - 16px)",
              }}
            />
          )}
        </DropZoneStyle>
      </RootStyle>
      {fileRejections.length > 0 && (
        <RejectionFiles fileRejections={fileRejections} />
      )}

      {helperText && helperText}
    </Box>
  );
}

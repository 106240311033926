import { lazy } from "react";
import { useRoutes } from "react-router-dom";
import ViewPDF from "src/components/pdf-viewer";
import useAuth from "src/hooks/useAuth";
import Loadable from "./Loadable";
import genAdmin from "./admin";
import auth from "./auth";
import main from "./main";
import TreeTest from "./test-tree";
import genUser from "./user";

const Lead = Loadable(lazy(() => import("src/pages/lead/index")));
const Replication = Loadable(lazy(() => import("src/pages/replication/index")));

const Router = () => {
  const { isSubAdmin, user } = useAuth();

  return useRoutes(getRoutes(isSubAdmin, user));
};
const getRoutes = (isSubAdmin, user) => {
  const routes = [
    ...auth,
    ...genUser(user),
    ...genAdmin(isSubAdmin),
    ...main,
    { path: "lead/:referral", element: <Lead /> },
    { path: "replication/:username", element: <Replication /> },
    {
      path: "/document/:name",
      element: <ViewPDF />,
    },
    {
      path: "tree/test",
      element: <TreeTest />,
    },
  ];

  return routes;
};

export default Router;
